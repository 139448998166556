#rcorners2 {
    border-radius: 15px;
    border: 1.5px solid #00a7bd;
    padding: 0px; 
    width: 135px;
    height: 70px;  
  }
  
  #rsocial {
    border-radius: 15px;
    border: 1.5px solid #ffffff;
    padding: 7px; 
    width: 50px;
    height: 50px;  
  }
  
  .round {
      border-radius: 50%;
      border-style: solid;
      border-width: 4px;
      border-color: #ffffff;
  }
  
  
  /* Gray Scale */
  .grayScale figure img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      -webkit-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
  }
  .grayScale figure:hover img {
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
  }
  
  .spin figure img {
    border-radius: 50%;
      -webkit-transition: -webkit-transform .8s ease-in-out;
      transition:         transform .8s ease-in-out;
  }
  .spin figure:hover img {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
  .al-right {
    float: right;
  }