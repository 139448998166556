.splashButton {
    width: 500px;
    font-size: 35px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.splashIconStyle {
    vertical-align: middle;
    padding-right: 5px;
}