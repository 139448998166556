/* .footer {
    margin-top: 1rem;
    padding: 0rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
} */


// .footer {
//     position: absolute;
//     bottom: 0;
//     width: 100%;
// }

.footer {
    margin-top: auto;
}

.links {
    color: #5a5a5a;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 0px;
    /* padding: 14px 10px; */
    padding: 1rem;
    text-transform: uppercase;
}

.navLinkColor {
    color: #5a5a5a;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 0px;
    /* padding: 14px 10px; */
    padding: 1rem;
    text-transform: uppercase;
}

.inactive {
    color: #fff;
    text-decoration: none;
}

.active {
    color: red;
    text-decoration: none;
}

.footIconStyle {
    vertical-align: middle;
    padding-left: 5px;
}

.footElement {
    padding-right: 10px;
}